@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('./fonts/NunitoSans-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('./fonts/NunitoSans-Black.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: NunitoSans, sans-serif;
  font-weight: 600;
}

.fix-position > .MuiPaper-root {
  top: 169px !important;
  right: 80px !important;
  left: unset !important;
}
